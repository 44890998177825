import {msalConfig, msalInstance} from './authConfig';
import {fireAuthError} from "./fireAuthError";
import {InteractionRequiredAuthError} from "@azure/msal-browser";

/*
getIdToken was inspired by
https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#acquiring-an-access-token-outside-of-a-react-component

 */
  export const getIdToken = async () => {
  try {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();
    if (!activeAccount && accounts.length === 0) {
      /*
       * User is not signed in. Throw error or wait for user to login.
       * Do not attempt to log a user in outside of the context of MsalProvider
       */
      throw new Error('User not logged in yet...');
    } else {
      const request = {
        scopes: [`${msalConfig.auth.clientId}/.default`],
        account: activeAccount || accounts[0]
      };
      try {
        const authResult = await msalInstance.acquireTokenSilent(request);
        return authResult.accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          // Tvinga en interaktiv inloggning
          return msalInstance.acquireTokenRedirect(request);
        }
        console.error("Token acquisition failed", error);
        throw error;
      }
    }
  } catch (e: any) {
    console.log("Error in getIdToken", e);
    console.error(e.toString());
    fireAuthError(401, e.toString());
  }
  return undefined;
};